import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
  },
  {
    path: "/collect-points",
    name: "collect-points",
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/CollectPoints.vue"),
  },
  {
    path: "/rewards",
    name: "rewards",
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/RewardsView.vue"),
  },
  {
    path: "/lucky-draws",
    name: "lucky-draws",
    component: () =>
      import(/* webpackChunkName: "lucky-draws" */ "@/views/LuckyDraws.vue"),
  },
  {
    path: "/terms-conditions",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "lucky-draws" */ "@/views/TermsView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "policy",
    component: () =>
      import(/* webpackChunkName: "lucky-draws" */ "@/views/PolicyView.vue"),
  },
  {
    path: "/liff-auth",
    name: "liff-auth",
    component: () =>
      import(/* webpackChunkName: "liff-auth" */ "@/views/LiffAuth.vue"),
  },
  {
    path: "*",
    name: "page-not-found",
    component: () =>
      import(/* webpackChunkName: "page-not-found" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
