// Setup : HTTP Request
import PointActivityApiProvider from "@/services/providers/PointActivityApiProvider";
const point_activity_api_provider = new PointActivityApiProvider();

export default {
  state: {
    collect_point_results: null,
    show_collect_point_info: false,
    redeem_point_result: null,
    show_shipping_info: false,
    show_redeem_point_info: false,
  },
  actions: {
    async requestCreateCollectPointActivity({ commit }, params) {
      commit("SET_LOADING");
      try {
        const response =
          await point_activity_api_provider.requestCreateCollectPointActivity(
            params
          );
        commit("SHOW_COLLECT_POINT_INFO_POPUP", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
    async requestCreateRedeemPointActivity({ commit }, params) {
      commit("SET_LOADING");
      try {
        const response =
          await point_activity_api_provider.requestCreateRedeemPointActivity(
            params
          );
        commit("SHOW_REDEEM_POINT_INFO_POPUP", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
  },
  mutations: {
    SHOW_COLLECT_POINT_INFO_POPUP(state, payload) {
      state.show_collect_point_info = true;
      state.collect_point_results = payload;
    },
    HIDE_COLLECT_POINT_INFO_POPUP(state) {
      state.show_collect_point_info = false;
      state.collect_point_results = null;
    },
    SHOW_SHIPPING_INFO_POPUP(state) {
      state.show_shipping_info = true;
    },
    HIDE_SHIPPING_INFO_POPUP(state) {
      state.show_shipping_info = false;
    },
    SHOW_REDEEM_POINT_INFO_POPUP(state, payload) {
      state.show_redeem_point_info = true;
      state.redeem_point_result = payload;
    },
    HIDE_REDEEM_POINT_INFO_POPUP(state) {
      state.show_redeem_point_info = false;
      state.redeem_point_result = null;
    },
  },
};
