<template>
  <v-app>
    <Navbar />

    <v-main>
      <v-row no-gutters>
        <v-img
          src="@/assets/header_bg.svg"
          transition="scroll-y-transition"
          style="position: absolute; width: 100vw"
        />
      </v-row>
      <router-view />
    </v-main>

    <Footer />
    <Loading />
    <ErrorDialog />
    <ProfileFormPopup />
  </v-app>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import ErrorDialog from "@/components/popups/ErrorDialog";
import ProfileFormPopup from "@/components/popups/ProfileFormPopup";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Loading,
    ErrorDialog,
    ProfileFormPopup,
  },
  methods: {
    ...mapMutations({
      setLineProfile: "SET_LINE_PROFILE",
    }),
    ...mapActions({
      requestGetUserProfile: "requestGetUserProfile",
      requestCreateAccessToken: "requestCreateAccessToken",
    }),
  },
  // async mounted() {
  //   const profile = {
  //     userId: "U99053045b88f3ce9b59b91bdcca8a074",
  //     displayName: "oath_",
  //     statusMessage: "practice makes perfect",
  //     pictureUrl:
  //       "https://profile.line-scdn.net/0hSvkqqkY4DFlWCBudcsJzDmpNAjQhJgoRLm8ROnUIVGp7axhYYmtEPCYAUGFzOEoIbDpFPyMJB255",
  //   };

  //   this.setLineProfile(profile);
  //   const token_status = await this.requestCreateAccessToken({
  //     line_id: profile.userId,
  //   });
  //   if (token_status) {
  //     await this.requestGetUserProfile();
  //   }

  //   if (this.$route.name == "liff-auth") {
  //     this.$router.replace({ name: "home" });
  //   }
  // },
  async beforeCreate() {
    // LIFF : init connection with line sdk
    await this.$liff.init({
      liffId: "1656839562-1pw446XR",
    });
    // LIFF : get line login status
    if (this.$liff.isLoggedIn()) {
      // LIFF : get line profile
      const profile = await this.$liff.getProfile();
      if (profile) {
        // STORE : save line profile
        this.setLineProfile(profile);
        // API : create token line profile
        const token_status = await this.requestCreateAccessToken({
          line_id: profile.userId,
        });
        if (token_status) {
          // API : get user profile
          await this.requestGetUserProfile();
        }
        if (this.$route.name == "liff-auth") {
          this.$router.replace({ name: "home" });
        }
      }
    } else {
      const auth_url = "https://nichicrm.kuritofoods.com/liff-auth";
      this.$liff.login({ redirectUri: auth_url });
    }
  },
};
</script>

<style>
.v-application {
  background-color: #faf3e6 !important;
}

.bring-to-front {
  z-index: 1 !important;
}
</style>
