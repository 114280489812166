<template>
  <v-overlay
    id="ProfileFormPopup"
    :opacity="0.3"
    :value="show_user_profile_form"
  >
    <v-dialog v-model="show_user_profile_form" persistent max-width="512">
      <v-card class="mx-auto" flat rounded="xl" color="nav_color">
        <v-card-title class="primary--text font-weight-bold justify-center"
          >รายละเอียดลูกค้าใหม่</v-card-title
        >
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <v-row justify="center">
            <v-col cols="12" lg="7" md="7" sm="8">
              <v-text-field
                dense
                rounded
                outlined
                clearable
                hide-details
                color="secondary"
                background-color="white"
                label="ชื่อ-สกุล"
                placeholder="ชื่อ-สกุล"
                v-model="fullname"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="7" md="7" sm="8">
              <v-text-field
                dense
                rounded
                outlined
                clearable
                hide-details
                color="secondary"
                background-color="white"
                label="เบอร์ติดต่อ หรือ อีเมลล์"
                placeholder="เบอร์ติดต่อ หรือ อีเมลล์"
                v-model="contact"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="7" md="7" sm="8">
              <v-textarea
                dense
                rounded
                outlined
                hide-details
                color="secondary"
                background-color="white"
                label="ที่อยู่จัดส่งของรางวัล"
                placeholder="ที่อยู่จัดส่งของรางวัล"
                v-model="address"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center">
          <div class="d-flex justify-space-between my-3">
            <v-btn
              rounded
              depressed
              color="btn_color"
              :disabled="is_disable_btn"
              @click.prevent="createUserButtonClicked()"
            >
              <span class="white--text">ยืนยัน</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProfileFormPopup",
  data: () => ({
    fullname: null,
    contact: null,
    address: null,
  }),
  computed: {
    ...mapState({
      line_profile: (state) => state.user.line_profile,
      show_user_profile_form: (state) => state.user.show_user_profile_form,
    }),
    is_disable_btn: function () {
      return !(this.fullname && this.contact && this.address);
    },
  },
  methods: {
    ...mapActions({
      requestCreateUser: "requestCreateUser",
      requestCreateAccessToken: "requestCreateAccessToken",
    }),
    async createUserButtonClicked() {
      if (!this.is_disable_btn) {
        // API : Create User
        await this.requestCreateUser({
          line_id: this.line_profile.userId,
          fullname: this.fullname,
          contact: this.contact,
          address: this.address,
        });

        await this.requestCreateAccessToken({
          line_id: this.line_profile.userId,
        });

        // Navigate : re-direct to home page
        if (this.$route.name != "home") {
          this.$router.replace({ name: "home" });
        }
      }
    },
  },
};
</script>

<style></style>
