import HttpRequest from "../HttpRequest";

class UserApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetUserProfile() {
    let request_url = "/users";
    this.setAuthorizationHeadersFromStore();
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateUser(params) {
    const request_url = "/users";
    const data = await this.create(request_url, params);

    return data;
  }
}

export default UserApiProvider;
