import HttpRequest from "../HttpRequest";

class ProductApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetProducts(product_type) {
    let request_url = "/products";
    if (product_type) {
      request_url += "?type=" + product_type;
    }
    this.setAuthorizationHeadersFromStore();
    const data = await this.get(request_url);
    return data;
  }
}

export default ProductApiProvider;
