import HttpRequest from "../HttpRequest";

class PointActivityApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestCreateCollectPointActivity(params) {
    this.setAuthorizationHeadersFromStore();
    const request_url = "/points/collect";
    const data = await this.create(request_url, params);

    return data;
  }

  async requestCreateRedeemPointActivity(params) {
    this.setAuthorizationHeadersFromStore();
    const request_url = "/points/redeem";
    const data = await this.create(request_url, params);

    return data;
  }
}

export default PointActivityApiProvider;
