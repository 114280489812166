<template>
  <div id="Footer">
    <v-row no-gutters class="mb-n3">
      <v-img
        src="@/assets/footer_bg.svg"
        transition="scroll-y-reverse-transition"
      />
    </v-row>
    <v-card flat tile class="footer_color white--text text-center">
      <v-card-text>
        <v-btn
          v-for="item in icons"
          :key="item.icon"
          class="mx-4 white--text"
          icon
          @click.prevent="openLink(item.url)"
        >
          <img
            v-if="item.icon == 'tiktok'"
            width="18px"
            src="@/assets/tiktok-brands.svg"
          />

          <v-icon v-else size="24px">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        ร่วมแชร์ประสบการณ์ ความสุขกับชานมไข่มุก ด้วยการ <b> #nichidrinks</b>
      </v-card-text>
      <v-card-text class="white--text">
        © {{ new Date().getFullYear() }} Nichi. All rights reserved
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <v-btn
          text
          small
          class="mx-4 white--text"
          @click.prevent="navigateToPage('terms')"
        >
          ข้อกำหนดและนโยบายความเป็นส่วนตัว
        </v-btn>
        <v-btn
          text
          small
          class="mx-4 white--text"
          @click.prevent="navigateToPage('policy')"
        >
          นโยบายกิจกรรม “สะสมแต้ม ลุ้นรางวัลกับ NICHI”
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    icons: [
      { icon: "mdi-facebook", url: "https://www.facebook.com/nichidrinks" },
      { icon: "mdi-instagram", url: "https://www.instagram.com/nichidrinks/" },
      { icon: "mdi-twitter", url: "https://twitter.com/NichiDrinks" },
      { icon: "tiktok", url: "https://www.tiktok.com/@nichidrinks" },
    ],
  }),
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    navigateToPage(page_name) {
      if (this.$route.name !== page_name) {
        this.$router.push({ name: page_name });
      }
    },
  },
};
</script>

<style></style>
