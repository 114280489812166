import store from "@/store";
import AES from "crypto-js/aes";

import dayjs from "dayjs";
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

export default {
  isTokenExist: function () {
    const token = store.state.access_token;
    return token && token != "";
  },
  getAccessToken: function () {
    const token = store.state.access_token;
    return this.isTokenExist() ? token : null;
  },
  encryptRequestData: function (value) {
    const ciphertext = AES.encrypt(
      value,
      process.env.VUE_APP_AES_KEY
    ).toString();
    return ciphertext;
  },
  toThousandSeparator: function (value, decimal) {
    return (value * 1).toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  isBetweenAllowDate() {
    const start_date = "2022-06-21";
    const end_date = "2022-07-26";
    const current_date = dayjs().format("YYYY-MM-DD");
    return dayjs(current_date).isBetween(start_date, end_date, null, "[]");
  },
};
