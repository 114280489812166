import axios from "axios";
import Helpers from "@/helpers";

class HttpRequest {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URI,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 10000,
    });

    // Interception : Request
    this.axiosInstance.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Interception : Response
    this.axiosInstance.interceptors.response.use(
      function (response) {
        // Do something with response data
        const response_data = response.data;
        switch (+response_data.code) {
          case 0:
            return response_data.data;

          default:
            return Promise.reject(response_data.message);
        }
      },
      function (error) {
        // Do something with response error
        switch (error.response.status) {
          case 401:
            window.location.replace("/login");
            return Promise.reject("Unauthorized! Please login again");

          default:
            return Promise.reject(error);
        }
      }
    );
  }

  setAuthorizationHeadersFromStore() {
    const access_token = Helpers.getAccessToken();
    if (access_token) {
      this.axiosInstance.defaults.headers.common["x-access-token"] =
        access_token;
    }
  }

  get(methodName, data) {
    return this.axiosInstance.get(methodName, {
      params: data,
    });
  }

  create(methodName, data) {
    const encrypted_param = Helpers.encryptRequestData(JSON.stringify(data));
    return this.axiosInstance.post(methodName, {
      data: encrypted_param,
    });
  }

  file(methodName, data) {
    return this.axiosInstance.post(methodName, data);
  }

  update(methodName, data) {
    const encrypted_param = Helpers.encryptRequestData(JSON.stringify(data));
    return this.axiosInstance.patch(methodName, {
      data: encrypted_param,
    });
  }

  delete(methodName, param, data) {
    return this.axiosInstance.delete(methodName, {
      params: param,
      data: data,
    });
  }

  request(type, url, data) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PATCH":
        promise = axios.patch(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default HttpRequest;
