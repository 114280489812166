import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPGfkSro80qpn4Ok95a3oDL1llFZngXEg",
  authDomain: "nichi-crm.firebaseapp.com",
  projectId: "nichi-crm",
  storageBucket: "nichi-crm.appspot.com",
  messagingSenderId: "990932643895",
  appId: "1:990932643895:web:97278bf333a24bbef04fb2",
  measurementId: "G-TMR7KGCC5C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.prototype.$analytics = analytics;

import liff from "@line/liff";
Vue.prototype.$liff = liff;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
