// Setup : HTTP Request
import ProductApiProvider from "@/services/providers/ProductApiProvider";
const product_api_provider = new ProductApiProvider();

export default {
  state: {
    product_list: [],
    reward_filter: "",
    selected_product_id: null,
    is_open_product_info: false,
  },
  getters: {
    rewards(state) {
      if (state.product_list && state.product_list.length > 0) {
        const filtered = state.product_list.filter(
          (product) => product.type == "reward"
        );

        // SORT by point_use from low to high
        return filtered.sort(
          (firstEl, secondEl) => firstEl.point_use - secondEl.point_use
        );
      }
      return [];
    },
    lucky_draws(state) {
      if (state.product_list && state.product_list.length > 0) {
        const filtered = state.product_list.filter(
          (product) => product.type == "lucky"
        );

        // SORT by point_use from A to Z
        return filtered.sort((firstEl, secondEl) => {
          if (firstEl.title < secondEl.title) {
            return -1;
          }
          if (firstEl.title > secondEl.title) {
            return 1;
          }
          return 0;
        });
      }
      return [];
    },
    selected_product(state) {
      if (
        state.selected_product_id &&
        state.product_list &&
        state.product_list.length > 0
      ) {
        const matched = state.product_list.find(
          (product) => product._id == state.selected_product_id
        );
        return matched;
      }
      return null;
    },
  },
  actions: {
    async requestGetProducts({ commit }, product_type) {
      commit("SET_LOADING");
      try {
        const response = await product_api_provider.requestGetProducts(
          product_type
        );
        commit("SET_PRODUCT_LIST", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
    showProductInfo({ commit }, product_id) {
      commit("SET_SELECTED_PRODUCT_BY_ID", product_id);
      commit("SHOW_PRODUCT_INFO_POPUP");
    },
    hideProductInfo({ commit }) {
      commit("HIDE_PRODUCT_INFO_POPUP");
    },
  },
  mutations: {
    SET_PRODUCT_LIST(state, payload) {
      state.product_list = payload;
    },
    SET_SELECTED_PRODUCT_BY_ID(state, payload) {
      state.selected_product_id = payload;
    },
    SHOW_PRODUCT_INFO_POPUP(state) {
      state.is_open_product_info = true;
    },
    HIDE_PRODUCT_INFO_POPUP(state) {
      state.is_open_product_info = false;
    },
  },
};
