<template>
  <div id="Navbar">
    <v-app-bar app color="nav_color" fixed>
      <div class="d-flex align-center" @click.prevent="navigateToPage('home')">
        <v-img
          alt="Vuetify Logo"
          class="shrink"
          contain
          src="@/assets/nichi_logo.svg"
          transition="scale-transition"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn icon color="title_color" @click.prevent="drawer = true">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary right color="nav_color">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="primary--text text-h6 font-weight-bold">
            เมนู
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item @click.prevent="navigateToPage('home')">
            <v-list-item-icon>
              <v-icon color="primary">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text">หน้าแรก</v-list-item-title>
          </v-list-item>

          <v-list-item @click.prevent="navigateToPage('collect-points')">
            <v-list-item-icon>
              <v-icon color="primary">mdi-piggy-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text"
              >สะสมพอยต์</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click.prevent="navigateToPage('rewards')">
            <v-list-item-icon>
              <v-icon color="primary">mdi-gift-open</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text"
              >แลกของรางวัล</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click.prevent="navigateToPage('lucky-draws')">
            <v-list-item-icon>
              <v-icon color="primary">mdi-star-shooting</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text"
              >ลุ้นโชคจับสลาก</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>

        <v-divider class="my-3"></v-divider>
        <v-list-item-group>
          <v-list-item @click.prevent="navigateToPage('terms')">
            <v-list-item-title class="primary--text"
              >ข้อกำหนดและนโยบายความเป็นส่วนตัว
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.prevent="navigateToPage('policy')">
            <v-list-item-title class="primary--text"
              >นโยบายกิจกรรม สะสมแต้ม ลุ้นรางวัลกับ NICHI
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data: () => ({
    drawer: false,
  }),
  methods: {
    navigateToPage(page_name) {
      if (this.$route.name !== page_name) {
        this.$router.push({ name: page_name });
      }
    },
  },
};
</script>

<style></style>
