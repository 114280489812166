import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#664022",
        nav_color: "#FAF3E6",
        secondary: "#B39670",
        title_color: "#664022",
        desc_color: "#3E3E3E",
        btn_color: "#8FB44E",
        footer_color: "#95A165",
      },
    },
  },
});
