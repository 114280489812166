// Setup : HTTP Request
import UserApiProvider from "@/services/providers/UserApiProvider";
const user_api_provider = new UserApiProvider();

export default {
  state: {
    line_profile: null,
    user_profile: null,
    show_user_profile_form: false,
  },
  getters: {
    total_points(state) {
      let total_point = 0;
      if (state.user_profile && state.user_profile.points.length > 0) {
        total_point = state.user_profile.points.reduce((sum, cur) => {
          return sum + cur.total_point;
        }, 0);
      }
      return total_point;
    },
  },
  actions: {
    async requestGetUserProfile({ commit }) {
      commit("SET_LOADING");
      try {
        const response = await user_api_provider.requestGetUserProfile();
        commit("SET_USER_PROFILE", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
    async requestCreateUser({ commit }, params) {
      commit("SET_LOADING");
      try {
        const response = await user_api_provider.requestCreateUser(params);
        commit("SET_USER_PROFILE", response);
        commit("HIDE_USER_PROFILE_FORM");
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
  },
  mutations: {
    SET_USER_PROFILE(state, payload) {
      state.user_profile = payload;
    },
    SET_LINE_PROFILE(state, payload) {
      state.line_profile = payload;
    },
    SHOW_USER_PROFILE_FORM(state) {
      state.show_user_profile_form = true;
    },
    HIDE_USER_PROFILE_FORM(state) {
      state.show_user_profile_form = false;
    },
  },
};
