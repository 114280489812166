import HttpRequest from "../HttpRequest";

class AuthApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestCreateAccessToken(params) {
    const request_url = "/auth/get-token";
    const data = await this.create(request_url, params);

    return data;
  }
}

export default AuthApiProvider;
